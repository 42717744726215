.user-dashboard-main-wrap {
  background: var(--card-color);
  padding: 25px;
  border-radius: 20px 0 0px 0px;
  min-height: 100vh;
}

.table-leads-new {
  min-width: 1280px;
  width: 100%;
}

.table-active-status-bg {
  background-color: #74cc5233;
  color: #333333 !important;
}

.table-inactive-status-bg {
  background-color: #fcebeb;
}

.table-pending-status-bg {
  background-color: #91b54d1a;
}

.team-invitation-heading h4 {
  font-size: 20px;
  font-style: normal;
  font-weight: var(--heading-weigth);
  line-height: 142.1%;
}

.team-invitation-heading h3 {
  font-weight: var(--heading-weigth);
  color: var(--font-color);
  font-size: var(--heading-size);
  margin-bottom: 0px !important;
}

.team-invitation-heading span {
  font-weight: var(--text-font-weight);
  color: var(--font-color);
}

.table-resp-overflow {
  /* overflow-y: hidden !important; */
  /* overflow-x: hidden; */
  min-height: max-content !important;
}

.user-info-dropdown button {
  min-width: 50px;
}

.heading-user-mange-wrap ul {
  list-style: none;
  margin-bottom: 0 !important;
}

.heading-user-mange-wrap ul li {
  display: inline-block;
  margin-right: 10px;
}

.heading-user-mange-wrap ul li h3 {
  font-weight: var(--heading-weigth);
  color: var(--font-color);
  font-size: var(--heading-size);
  position: relative;
  top: 4px;
  min-width: 185px;
}

.heading-user-mange-wrap ul li h3 span {
  font-weight: var(--text-font-weight);
  color: var(--font-color);
}

.heading-user-mange-wrap ul li .search-group-wraper {
  position: relative;
  flex: 1;
}

.search-group-wraper .form-control {
  height: 35px;
  border: 1px solid var(--divider-color);
  background-color: var(--card-color);
  color: var(--font-color);
  box-shadow: none;
  font-size: 12px;
  padding-right: 35px;
  border-radius: 4px;
  min-width: 330px;
}

.search-group-wraper .form-control::placeholder {
  color: var(--font-color);
  opacity: 0.5;
}

.search-group-wraper button {
  position: absolute;
  right: 4px;
  background: none;
  top: 4px;
  outline: none;
  border: none;
  color: var(--app-color-2);
}

.search-group-wraper button img {
  width: 20px;
}

.heading-user-mange-wrap ul li:nth-child(2) {
  width: 50% !important;
}

.button-filter-wraper button {
  height: 35px;
  border: 1px solid #e9eeff;
  background: none;
  padding: 0 28px;
  border-radius: 6px;
  font-weight: var(--heading-weigth);
  color: var(--app-color-2);
  font-size: var(--text-size);
}

.button-filter-wraper button img {
  width: 15px;
}

.button-top-right-wrap {
  text-align: right;
}

/* .button-top-right-wrap button {
  margin-left: 4px;
  height: 35px;
  border: 1px solid #e9eeff;
  background: none;
  padding: 0 25px;
  border-radius: 6px;
  font-weight: var(--heading-weigth);
  color: var(--font-color);
  font-size: var(--text-size);
}

.button-top-right-wrap button:hover {
  background-color: var(--app-color-1);
  color: #fff;
  border: 1px solid var(--app-color-1);
}

.button-top-right-wrap button:hover svg {
  color: #fff;
}

.button-top-right-wrap button svg {
  width: 15px;
  color: var(--app-color-1);
} */

.table-user-management-wrap {
  margin-top: 20px;
  /* min-height: 190px !important; */
  /* min-height: 100%; */
}

.table-user-management-wrap .table-responsive {
  /* min-height: 200px; */
  padding-bottom: 54px;
}

.user-management-main-page-height {
  min-height: 92vh;
  flex: 1;
}

.table-two-div {
  padding-bottom: 100px;
}

.table-user-management-wrap .table-two {
  border: 1px solid #f1f4ff;
}

.table-user-management-wrap .table-two tr td {
  border: 1px solid #f1f4ff;
  vertical-align: middle;
  font-size: var(--text-size);
  padding: 5px 15px;
  font-weight: var(--text-font-weight);
}

.heading-table-wrap {
  font-weight: bold;
  color: #3a4a83;
  font-size: 16px;
}

.heading-table-wrap td {
  font-weight: 500 !important;
}

.control-group-leads {
  display: block;
  vertical-align: top;
  background: #fff;
  text-align: left;
}

.control-leads {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: var(--text-size);
  font-weight: var(--heading-weigth);
}

.control-leads input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control___indicator {
  position: absolute;
  top: -18px;
  left: 0;
  height: 20px;
  width: 24px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #f1f4ff !important;
}

.control-leads--radio .control___indicator {
  border-radius: 50%;
}

.control-leads:hover input ~ .control___indicator,
.control-leads input:focus ~ .control___indicator {
  background: #fff;
}

.control-leads input:checked ~ .control___indicator {
  background: var(--app-color-1);
}

.control-leads:hover input:not([disabled]):checked ~ .control___indicator,
.control-leads input:checked:focus ~ .control___indicator {
  background: #d52b2e;
}

.control-leads input:disabled ~ .control___indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.control___indicator:after {
  content: "";
  position: absolute;
  display: none;
}

.control-leads input:checked ~ .control___indicator:after {
  display: block;
}

.control--checkbox .control___indicator:after {
  left: 10px;
  top: 4px;
  width: 4px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.control--checkbox input:disabled ~ .control___indicator:after {
  border-color: #7b7b7b;
}

.control-leads--radio .control___indicator:after {
  left: 7px;
  top: 0;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}

.control-leads--radio input:disabled ~ .control___indicator:after {
  background: #7b7b7b;
}

.select {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
}

.select select {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
  outline: 0;
  border: 0;
  border-radius: 0;
  background: #e6e6e6;
  color: #7b7b7b;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.select select::-ms-expand {
  display: none;
}

.select select:hover,
.select select:focus {
  color: #000;
  background: #ccc;
}

.select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.select__arrow {
  position: absolute;
  top: 16px;
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-style: solid;
  border-width: 8px 5px 0 5px;
  border-color: #7b7b7b transparent transparent transparent;
}

.select select:hover ~ .select__arrow,
.select select:focus ~ .select__arrow {
  border-top-color: #000;
}

.select select:disabled ~ .select__arrow {
  border-top-color: #ccc;
}

.nama-table-wrap {
  cursor: pointer;
}

.nama-table-wrap img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 5px;
}

.nama-table-wrap span {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  background: #ffaeae;
  text-align: center;
  color: #fff;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  /* padding-top: 7px; */
  margin-right: 5px;
}

.email-table-wrap a {
  font-size: var(--text-size);
  text-decoration: none;
  color: var(--font-color);
}

.select-main-wrap {
}

.select-main-wrap .form-control {
  height: 35px;
  border: 1px solid #ccc;
  box-shadow: none;
  outline: none;
  border-radius: 0;
  border: 1px solid #f1f4ff;
  color: #000;
  font-size: 12px;
}

.status-table {
  text-align: center;
  /* color: #212529 !important;
  background: #ece1d3 !important; */
  height: 35px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-table-active {
  background-color: #c6eaa3 !important;
  color: #fff !important;
}

.status-table-pending {
  background-color: #ffaeae !important;
  color: #fff !important;
}

.teams-listing-wap {
  display: flex;
  flex-wrap: nowrap;
}

.teams-listing-wap ul {
  display: contents;
  list-style: none;
  margin: 0 !important;
}

.teams-listing-wap ul li {
  background: #b0e57c;
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  font-weight: var(--heading-weigth);
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.teams-listing-wap ul li:not(:first-child) {
  margin-left: -14px;
}

/* .teams-listing-wap ul li:nth-child(3) {
  margin-left: -14px;
  background: #b4d8e7 !important;
} */

.teams-member-listing-wap {
  display: flex;
  flex-wrap: nowrap;
}

.teams-member-listing-wap ul {
  display: contents;
  list-style: none;
  margin: 0 !important;
}

.teams-member-listing-wap ul li {
  background: var(--card-color);
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  color: var(--font-color);
  font-size: var(--text-size);
  font-weight: var(--heading-weigth);
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.teams-member-listing-wap ul li:not(:first-child) {
  margin-left: -14px;
}

.teams-member-listing-wap-profile {
  /* margin-left: 10px; */
}

.teams-member-listing-wap-profile ul li {
  padding-top: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.teams-member-listing-wap-profile ul li:not(:first-child) {
  margin-left: -8px !important;
}

.teams-member-listing-wap-more-btn {
  border: 1px solid var(--app-color-2);
  color: var(--font-color) !important;
  font-size: var(--text-size);
  font-weight: var(--heading-weigth);
}

.colorful-list li.color-0 {
  background-color: #b0e57c;
  /* Color for the first background */
}

.colorful-list li.color-1 {
  background-color: #56baec;
  /* Color for the second background */
}

.colorful-list li.color-2 {
  background-color: #b4d8e7;
  /* Color for the third background */
}

.ag-row-selected div {
  background-color: #fff !important;
}

.user-info-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-info-dropdown button {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #f1f4ff;
  color: #212529;
  width: 50px;
  padding: 0;
}

.user-info-dropdown a,
.roles-drop-down a {
  font-size: var(--text-size);
  color: var(--font-color);
  font-weight: var(--text-font-weight);
}

.user-info-dropdown a img {
  width: 18px;
  margin-right: 5px;
}

.user-info-dropdown button:hover {
  background-color: #fff;
  color: #fff;
  border: 1px solid #f1f4ff;
}

.user-info-dropdown button:focus {
  background-color: #fff !important;
  color: #fff;
  border: 1px solid #f1f4ff !important;
  outline: none;
}

.user-info-dropdown button::after,
.roles-drop-down button::after {
  display: none !important;
}

.user-info-dropdown .dropdown-menu {
  min-width: 7rem;
}

.roles-drop-down button,
.roles-drop-down button:hover,
.roles-drop-down button:focus {
  border: none;
  padding: 0;
  font-size: var(--text-size);
  background-color: var(--card-color) !important;
  color: var(--font-color);
}

.user-info-dropdown-img {
  width: 100%;
  height: 100%;
}

.update-modal-body {
  padding-top: 0 !important;
  font-family: var(--text-font-family) !important;
}

.update-modal-header {
  padding-bottom: 0 !important;
  border-bottom: none !important;
}
.update-modal-body .form-control {
  background-color: var(--search-bar-bg);
  color: var(--font-color);
  border: 1px solid var(--divider-color);
}
.update-modal-body .form-control::placeholder {
  color: var(--font-color);
  opacity: 0.5;
}
.modal-module-wrapper,
.modal-role-wrapper {
  margin-bottom: 10px;
}

.modal-module-wrapper h6,
.modal-role-wrapper h6 {
  font-size: var(--heading-size);
}

.modules-opt-wrapper,
.roles-opt-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.modules-opt-wrapper div,
.roles-opt-wrapper div {
  background-color: #e9eeff;
  color: #000;
  min-width: 40px;
  min-height: 40px;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: var(--text-size);
  cursor: pointer;
  margin-top: 5px;
}

.modules-opt-wrap {
  padding-right: 0 !important;
}

.modules-opt-wrap div {
  background-color: var(--text-message-bg-1);
  color: var(--font-color);
  padding: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--text-size);
  cursor: pointer;
  margin-bottom: 10px;
}

.company-modal-row {
  margin-right: 0px !important;
}

.role-selected-modal,
.roles-opt-wrapper div:hover,
.modules-opt-wrapper div:hover,
.modules-opt-wrap div:hover {
  background-color: var(--icon-color) !important;
  color: #fff !important;
}

.modal-btn-wrapper button {
  background-color: var(--app-color-2);
  color: #fff;
  border-radius: 6px;
  border: 1px solid var(--app-color-2);
  font-size: 12px;
  padding: 5px 15px;
}

.modal-btn-wrapper button:hover {
  background-color: var(--app-color-1);
  border: 1px solid var(--app-color-1);
}

.main-update-modal .modal-content {
  border-radius: 24px;
  padding: 10px;
}

.modal-btn-wrapper button {
  min-width: 120px;
  min-height: 42px;
}

.spinner-role {
  width: 1rem !important;
  height: 1rem !important;
}

.main-delete-modal .modal-content {
  padding: 20px 0 0 0 !important;
  border-radius: 20px;
  width: 300px;
  border: none;
  overflow: hidden;
  font-family: var(--text-font-family);
}

.main-delete-modal .modal-footer {
  padding: 0 !important;
}

.main-delete-modal .modal-footer > * {
  margin: 0 !important;
}

.del-modal-img-container img {
  margin-bottom: 20px;
}

.delete-btn-1 {
  background-color: var(--icon-color2);
  border: none;
  height: 50px;
  color: #fff;
}

.delete-btn-2 {
  background-color: var(--icon-color);
  border: none;
  height: 50px;
  color: #fff;
}

.delete-btn-2:hover {
  background-color: var(--icon-color);
}

.delete-btn-1:hover {
  background-color: var(--icon-color);
}

.del-modal-name {
  color: var(--app-color-1);
  font-size: var(--heading-size);
}

.ag-theme-alpine {
  --ag-alpine-active-color: #94abff !important;
  overflow: visible !important;
}

.ag-row,
.ag-center-cols-container,
.ag-center-cols-viewport,
.ag-cell,
.ag-body-viewport {
  overflow: visible !important;
}

.ag-theme-alpine .ag-body-viewport,
.ag-theme-alpine .ag-header-viewport {
  z-index: 1 !important;
  /* Adjust this value as needed */
}

.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-viewport {
  min-height: auto !important;
}

.status-table-wrap {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: yellowgreen; */
}

.status-table-inactive-text {
  /* color: #d52b2e; */
}

.status-table-indicator {
  width: 10px;
  height: 10px;
  min-width: 10px;
  background: #d52b2e;
  border-radius: 50%;
  margin-right: 5px;
}

.status-table-active {
  background: greenyellow;
}

.team-list-header-main {
  margin-top: 30px;
}

.advance-search-tag-close-icon {
  position: absolute !important;
  cursor: pointer !important;
  top: 2px !important;
  right: 2px !important;
  color: var(--app-color-1) !important;
}

.user-listing-search-section {
  margin-left: 10px;
}

.user-listing-search-section-wrap {
  align-items: center;
}

.advance-search-history-date {
  font-size: 12px !important;
  border: none !important;
  background: #e9eeff !important;
  padding: 8px 10px;
  box-shadow: none !important;
  outline: none;
  margin-left: 5px;
  height: 34px;
}

.advance-search-history-date input {
  background: transparent !important;
  font-size: 12px !important;
  min-width: 100px !important;
}

.leads-new-aggrid .ag-ltr .ag-cell-inline-editing {
  padding: 0px !important;
}

/* tooltip functionality in team listing table  */
.custom-tooltip {
  position: absolute;
  width: max-content;
  top: -42px;
  z-index: 9999 !important;
  height: 40px;
  padding: 10px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.702);

  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.custom-tooltip-rolename {
  margin-top: 2px;
  color: white;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.custom-tooltip span {
  font-weight: bold;
}

/* You can add additional styling as needed */
.ag-overlay-loading-custom {
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ag-overlay-no-rows-custom {
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.leads-new-aggrid {
  opacity: 1;
}

.leads-new-aggrid.show-table {
  opacity: 1;
  animation: fadeIn 1s ease-in-out;
}

.leads-new-aggrid .ag-row:hover {
  background-color: var(--chat-input-bg) !important;
  border-top: 1px solid #94abff !important;
  border-bottom: 1px solid #94abff !important;
}

.invite-ag-table .ag-row:hover {
  background-color: var(--chat-input-bg) !important;
  border-top: 1px solid #94abff !important;
  border-bottom: 1px solid #94abff !important;
}

.create-task-modal-header button,
.custom-modal-close-btn {
  align-items: center;
  background: var(--search-bar-bg);
  border: 1px solid var(--divider-color);
  border-radius: 6px;
  color: var(--font-color);
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1466px) {
  .leads-new-header-cols {
    width: 100% !important;
  }

  .button-top-right-wrap {
    text-align: start;
  }

  .cols-second-margin {
    margin-top: 20px !important;
  }

  .btn-second-col-one {
    margin-left: 0 !important;
  }
}

@media (max-width: 1200px) {
  .main-advance-search-modal .modal-dialog {
    max-width: fit-content;
  }

  .main-advance-search-modal .modal-content {
    min-width: 98vw !important;
  }
}

@media (max-width: 576px) {
  .main-advance-search-modal .modal-dialog {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 622px) {
  .heading-user-mange-wrap ul li:nth-child(2) {
    width: 100% !important;
    margin-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .user-listing-search-section div:first-child {
    flex-direction: column;
  }
}

@media (max-width: 820px) {
  .user-listing-search-section-li {
    flex-direction: column;
  }

  .user-listing-search-section {
    margin-left: 0;
    margin-top: 15px;
  }

  .user-listing-search-section-wrap {
    align-items: start;
  }
}

@media (max-width: 569px) {
  .heading-user-mange-wrap ul li {
    width: 100% !important;
  }

  .button-filter-wraper button {
    width: 100%;
  }

  .button-top-right-wrap button {
    width: 100%;
    margin-left: 0px !important;
    margin-bottom: 20px;
  }

  .search-group-wraper .form-control {
    min-width: 100%;
  }

  .user-listing-search-section-wrap,
  .user-listing-search-section-wrap div,
  .user-listing-search-section-wrap .custom-button-main {
    width: 100%;
  }

  .cols-second-margin {
    margin-top: 0px !important;
  }

  .button-top-right-wrap button:first-child {
    margin-bottom: 10px !important;
  }

  .user-listing-quick-search-list-wrap {
    margin-bottom: 5px !important;
  }
}
