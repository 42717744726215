.schedule-auth-wrapper {
  padding: 0px 110px 0;
}

.timezone-auth-form-container {
  /* border-radius: 4px;
  border: 1px solid #d4d4d4;
  background: #fff;
  box-shadow: 0px 4px 19.2px 0px rgba(0, 0, 0, 0.07);
  margin-bottom: 90px;
  padding: 35px; */
}

.set-hour-container {
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  background: #fff;
  box-shadow: 0px 4px 19.2px 0px rgba(0, 0, 0, 0.07);
  margin-bottom: 90px;
  padding: 35px;
}

.timezone-select-auth .select__control {
  border: 1px solid #e9eeff;
  border-radius: 4px;
  font-size: var(--text-size);
  padding: 0px !important;
  max-height: 40px !important;
  min-height: 40px !important;
  height: auto;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.project-details-main-date-filter input {
  border: none !important;
  width: 100px !important;
  padding: 0px !important;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: var(--font-color);
  cursor: pointer;
  background: inherit;
}

.project-details-main-date-filter input:focus {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}

.project-details-main-date-filter svg {
  color: var(--icon-color);
  margin-bottom: 4px;
  margin-right: 4px;
}

.project-details-main-date-filter .flatpickr-day.selected,
.custom-date-picker-dropdown .flatpickr-day.selected {
  background-color: var(--app-color-1) !important;
  border: 1px solid var(--app-color-1) !important;
}

.project-details-main-date-filter .flatpickr-weekday,
.custom-date-picker-dropdown .flatpickr-weekday {
  color: white !important;
}

.project-details-main-date-filter .flatpickr-weekdays,
.custom-date-picker-dropdown .flatpickr-weekdays {
  background-color: var(--app-color-1) !important;
}

.project-details-main-date-filter .flatpickr-month,
.custom-date-picker-dropdown .flatpickr-month {
  background-color: var(--app-color-1) !important;
  color: white !important;
}

.project-details-main-date-filter .flatpickr-monthDropdown-months,
.custom-date-picker-dropdown .flatpickr-monthDropdown-months {
  background-color: var(--app-color-1) !important;
  color: white !important;
}

.project-details-main-date-filter .flatpickr-prev-month,
.flatpickr-next-month {
  color: var(--app-color-1) !important;
}

/* Container for the entire body content */
.set-hour-container {
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* Section Labels */
.set-hour-label {
  align-items: center;
  display: flex;
  font-size: var(--text-size);
  font-weight: var(--heading-weight);
  margin-bottom: 8px;
  color: var(--exotic-font-color);
}

/* Input field styles */
.set-hour-input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--divider-color);
  background: var(--search-bar-bg);
  color: var(--font-color);
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  height: 40px;
}

.set-hour-input:focus {
  background: var(--card-color);
  color: var(--font-color);
}

/* Days Section */
.set-hour-days-section {
  display: flex;
  flex-direction: column;
}

.set-hour-days-container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

/* Day Button Styles */
.set-hour-day-button {
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid var(--divider-color);
  background: var(--search-bar-bg);
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: var(--font-color);
}

.set-hour-day-button:hover {
  background-color: var(--icon-color);
  border-color: var(--icon-color);
  color: #fff;
}

.set-hour-day-selected {
  background-color: var(--icon-color);
  color: #fff;
  border-color: var(--icon-color);
}

/* Timing Section */
.set-hour-timing-section {
  display: flex;
  flex-direction: column;
}

.set-hour-timing-container {
  display: flex;
  gap: 16px;
}

.set-hour-time {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.set-hour-time-label {
  font-size: 14px;
  color: var(--font-color);
  margin-bottom: 8px;
}

.set-hour-time-input {
  width: 100%;
  padding: 10px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
  transition: border-color 0.2s;
}

/* .set-hour-time-input:focus {
  border-color: #007bff;
} */

.set-hour-input svg {
  width: 20px;
  height: 20px;
  margin-bottom: 0;
  color: var(--app-color-1);
}

.select-hour-icon-date {
  position: absolute;
  right: 0;
}

.invalid-feedback-days {
  text-align: end;
  color: #e93131;
  font-size: 13px;
}

.select-hour-time-picker-slot {
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  transition: all 0.15s ease;
  border: 1px solid var(--divider-color) !important;
  color: var(--exotic-font-color);
  font-weight: 500;
  background-color:var(--card-color) !important;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition */
}

.select-hour-time-picker-slot:hover {
  background-color: var(--divider-color) !important;
  color: var(--icon-color);
}

.select-hour-time-picker-slot.active {
  background-color: var(--icon-color) !important;
  color: white;
}

.select-hour-time-picker-dropdown {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 10px;
  width: 100%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  background: var(--search-bar-bg);
  border: 1px solid var(--divider-color);
  border-radius: 4px;
  position: absolute;
  z-index: 1000;
}

/* Custom scrollbar for time slots */
.select-hour-time-picker-dropdown::-webkit-scrollbar {
  width: 6px;
}

.select-hour-time-picker-dropdown::-webkit-scrollbar-track {
  background: var(--card-color);
  border-radius: 10px;
}

.select-hour-time-picker-dropdown::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.select-hour-time-picker-dropdown::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.invalid-field {
  text-align: end;
  color: #e93131;
  font-size: 13px;
  margin-top: 0.25rem;
}


@media (max-width: 1630px) {
  .schedule-auth-wrapper {
    padding: 0px 55px 0;
  }
}

@media (max-width: 1410px) {
  .schedule-auth-wrapper {
    padding: 0px 27px 0;
  }
}

@media (max-width: 600px) {
  .schedule-auth-wrapper {
    padding: 25px 0px 0px 0px;
  }
}
