.packages-wrapper {
  align-items: center;
  background-position: 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  height: 100vh;
  overflow-y: scroll;
}

.packages-wrapper h3 {
  margin-bottom: 30px;
  font-size: 32px;
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.packages-card {
  border: 1px solid var(--app-color-2);
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.package-card-btn {
  cursor: pointer;
  width: 50%;
  margin: 0 auto;
  background-color: var(--app-color-1);
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.package-card-btn:hover {
  background-color: var(--app-color-2);
}

.package-card-btn {
  color: white;
  font-size: 20px;
  text-decoration: none;
  border: none;
}

.package-listing-signup .form-control {
  -webkit-appearance: auto !important;
  appearance: auto !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  margin-right: 5px !important;
  width: 150px !important;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.package-listing-signup .seats-form-wrap {
  width: 152px;
}

.package-listing-signup .form-group {
  display: flex;
  vertical-align: middle;
  align-items: center;
  margin-bottom: 15px;
}

.package-auth-row {
  padding: 5px !important;
  display: inline !important;
}

.package-auth-row .listing-pacakge-wraper {
  display: block !important;
}

.sub-main-radio {
  top: 28px !important;
}

.subscription-main-wraper {
  background: var(--card-color);
  border-radius: 20px 0 0px 0px;
  min-height: 100vh;
  overflow: hidden;
}

.custom-horizental-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.custom-horizental-scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

.custom-horizental-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb);
  border-radius: 5px;
}

.subscription-row {
  margin-right: 0 !important;
}

.subscription-main-wraper .top-page-heading-wrap {
}

.heading-subscription-wrap {
  font-weight: bold;
  color: #000;
  font-size: var(--heading-size);
  padding: 25px;
}

.subscription-button {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}

.subscription-button button {
  background: var(--app-color-2);
  border: none;
  padding: 10px 25px;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  border-radius: 6px;
}

.nav-subscription-wrap {
  /* background: var(--app-color-1); */

  padding: 25px 25px 0 25px;
}

.nav-subscription-wrap ul {
  list-style: none;
  background: var(--card-color);
  margin-bottom: 0 !important;
  position: relative;
  overflow-x: auto;
  overflow-y: visible;
  display: flex;
  flex-wrap: nowrap;
  z-index: 0;
}

.nav-subscription-wrap ul div {
  position: absolute;
  overflow: hidden;
  height: 2px;
  bottom: 0;
  z-index: -1;
  background-color: var(--divider-color);
  border-radius: 5px;
}

.nav-subscription-wrap ul li {
  display: inline-block;

  width: max-content;
}

.nav-subscription-wrap ul li a {
  display: block;
  padding: 12px 30px;
  color: var(--font-color) !important;
  text-decoration: none;
  font-size: var(--text-size);
  width: max-content;
  font-weight: var(--heading-weigth);
  cursor: pointer;
}

.nav-subscription-wrap ul li a:hover {
  border-bottom: 2px solid var(--icon-color2) !important;
}

.active-overview {
  border-bottom: 2px solid var(--icon-color2) !important;

  z-index: 3;
}

.content-subscription-wrap {
  padding: 25px;
}

.content-subscription-wrap h3 {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}

.content-subscription-wrap h3 span {
  font-weight: var(--heading-weigth);
}

.plan-listing-wraper {
  border: 1px solid #eef2ff;
  margin-top: 10px;
  border-radius: 10px;
  max-width: 1260px;
}

.plan-listing-wraper ul {
  list-style: none;
  margin-bottom: 0 !important;
}

.plan-listing-wraper ul li {
  display: inline-block;
  border-right: 1px solid #eef2ff;
  padding: 15px;
  font-size: 16px;
  line-height: 22px;
  width: 19.5%;
}

.plan-listing-wraper ul li:nth-child(5) {
  border: none;
  padding-right: 0;
}

.subscription-main-text-wrap {
}

.subscription-main-text-wrap strong {
  display: block;
  color: var(--font-color);
}

.subscription-main-text-wrap span {
  color: var(--app-color-1);
}

.subscription-main-text-wrap div {
  color: var(--font-color);
  font-weight: var(--heading-weigth);
}

.subscription-main-text-wrap .plan-details-span {
  font-weight: var(--text-font-weight);
  font-size: var(--text-size);
}

.subscription-button-new {
}

.subscription-button-new a {
  background: var(--app-color-1);
  padding: 14px 35px;
  color: #fff;
  font-weight: var(--heading-size);
  font-size: 13px;
  border-radius: 6px;
  text-decoration: none;
  font-size: var(--text-size);
}

.subscription-button-new {
  position: relative;
  top: -10px;
}

.sub-btn-overview {
  top: 0 !important;
  padding-left: 30px;
}

.sub-btn-overview a {
  cursor: pointer;
  color: #fff;
}

.plan-listing-wraper {
  margin-bottom: 15px;
}

.table-heading-module-wrap {
}

.table-heading-module-wrap {
}

.table-module-wrap h3 {
  margin-bottom: 15px;
}

.table-module-wrap .table-responsive {
  border: 1px solid var(--divider-color);
  border-radius: 10px;
}

.table-module-wrap .table-responsive .table {
  border: 1px solid var(--divider-color);
  border-radius: 10px;
}

.table-module-wrap .table-responsive .table {
  margin: 0;
  padding: 0;
}

.table-module-wrap .table tr {
  border: 1px solid var(--divider-color);
  vertical-align: middle;
}

.table-module-wrap .table tr td {
  border: 1px solid var(--divider-color);
  vertical-align: middle;
  padding: 0px 15px;
  font-weight: var(--text-font-weight);
}

.table-module-wrap .short-name-wrap {
  background: #000;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  padding-top: 5px;
  font-size: 11px;
  font-weight: bold;
  float: left;
  margin-right: 5px;
}

.table-heading-module-wrap span {
  position: relative;
  top: 2px;
  color: #000;
  font-weight: var(--heading-weigth);
}

.text-table-wrap {
  color: var(--font-color);
  font-size: var(--text-size);
}

.content-billing-detail-wraper {
  padding: 25px 25px 0 25px;
}

.content-billing-detail-wraper h3,
.payment-method-wrap h3 {
  font-size: 20px;
  color: var(--font-color);
  font-weight: var(--text-font-weight);
  margin-bottom: 15px;
}

.content-billing-detail-wraper h3 span,
.payment-method-wrap h3 span {
  font-weight: var(--heading-weigth);
}

.table-billing-info-wrap div {
  border: 2px solid var(--divider-color);
  border-radius: 10px !important;
}

.modify-package:hover {
  background-color: var(--icon-color2);
  color: #fff;
  border: 1px solid var(--icon-color2);
  border-radius: 4px;
}

.modify-package-del {
  background: transparent;
  border: none;
}

.table-billing {
  min-width: 900px;
}

.table-billing-info-wrap .table-responsive .table {
  margin-bottom: 0;
}

.table-billing-info-wrap .table-responsive .table tr td {
  border: none;
  font-size: var(--text-size);
  vertical-align: middle;
  background-color: var(--card-color);
  color: var(--font-color);
  
}

.stripe-img {
  width: 80px;
}

.billing-options {
  display: flex;
  align-items: center;
  justify-content: end;
  border: none !important;
}

.delete-card-info-btn {
  width: 20px;
}

.modify-package {
  margin-left: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--app-color-1);
  font-size: var(--text-size);
  border: 1px solid #f1f4ff;
  background-color: transparent;
  border: 1px solid var(--icon-color);
  color: var(--icon-color);
  /* border-radius: 4px; */
}

.table-billing-info-wrap .table tr td a {
  color: #ef2727;
  text-decoration: none;
}

.table-overview {
  padding: 15px !important;
  /* border: 1px solid #eef2ff; */
  min-width: 1255px;
  max-width: fit-content;
}

.table-overview-main {
  max-width: 1260px;
}

.table-overview tr td {
  border-right: 1px solid #eef2ff;
}

.table-overview tr {
  cursor: grab;
}

.table-overview tr:active {
  cursor: grabbing;
}

.overview-modules-table {
  min-width: 878px;
}

.overview-modules-table thead th {
  padding: 10px 15px;
  border-right: 1px solid var(--divider-color);
  font-size: var(--text-size);
  font-weight: var(--heading-weigth);
  color: var(--font-color);
}

.overview-modules-table thead :last-child {
  border-right: 0px;
}

.payment-method-wrap {
  padding: 0 25px;
}

.payment-method-wrap h3 a {
  color: var(--app-color-2);
  text-decoration: none;
  font-size: 16px;
}

.payment-method-wrap ul {
  list-style: none;
}

.payment-method-wrap ul li {
  display: inline-block;
  margin-right: 10px;
}

.payment-method-wrap ul li a {
  display: block;
  text-decoration: none;
  border: 1px solid #eef2ff;
  padding: 15px 10px;
  border-radius: 6px;
}

.payment-method-wrap ul li a:hover {
  border: 1px solid var(--app-color-1);
}

.payment-method-wrap ul li a img {
  width: 120px;
}

.control-settings-group {
  display: block;
  vertical-align: top;
  background: #fff;
  text-align: left;
}

.control-settings {
  display: block;
  position: relative;
  padding-left: 38px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: var(--text-size);
  font-weight: 400;
  color: #000;
}

.control-settings input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control__indicator__settings {
  position: absolute;
  top: -2px;
  left: 0;
  height: 23px;
  width: 25px;
  background: #eef2ff;
  border-radius: 0;
}

.control--radio .control__indicator__settings {
  border-radius: 50%;
}

.control-settings:hover input ~ .control__indicator__settings,
.control input:focus ~ .control__indicator__settings {
  background: #c4cbe2;
}

.control-settings input:checked ~ .control__indicator__settings {
  background: var(--font-color);
}

.control-settings:hover
  input:not([disabled]):checked
  ~ .control__indicator__settings,
.control input:checked:focus ~ .control__indicator__settings {
  background: #d52b2e;
}

.control-settings input:disabled ~ .control__indicator__settings {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.control__indicator__settings:after {
  content: "";
  position: absolute;
  display: none;
}

.control-settings input:checked ~ .control__indicator__settings:after {
  display: block;
}

.control--checkbox-settings .control__indicator__settings:after {
  left: 10px;
  top: 6px;
  width: 4px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.control--checkbox-settings
  input:disabled
  ~ .control__indicator__settings:after {
  border-color: #7b7b7b;
}

.control--radio .control__indicator__settings:after {
  left: 7px;
  top: 0;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}

.control--radio input:disabled ~ .control__indicator__settings:after {
  background: #7b7b7b;
}

.content-payment-info-wraper {
  padding: 25px 25px 0 25px;
}

.content-payment-info-wraper h3 {
  font-size: 20px;
  color: var(--font-color);
  font-weight: var(--text-font-weight);
  margin-bottom: 15px;
}

.content-payment-info-wraper h3 span {
  font-weight: var(--heading-weigth);
}

.content-payment-info-wraper {
}

.content-payment-info-wraper .table {
  border: 1px solid #eef2ff;
}

.content-payment-info-wraper .table tr {
  border: none !important;
}

.content-payment-info-wraper .table tr td {
  border: none;
  border-right: 1px solid var(--divider-color);
  border-bottom: 1px solid var(--divider-color);
  background-color: var(--card-color);
  color: var(--font-color);
  padding: 10px 15px;
}

.content-payment-info-wraper .table thead {
  border-bottom: 1px solid var(--divider-color);
}

.content-payment-info-wraper .table tbody tr {
  border-bottom: 1px solid #eef2ff;
}

.content-payment-info-wraper .table tbody:last-child {
  border-bottom: none !important;
}

.content-payment-info-wraper .table tr td strong {
  display: block;
  color: var(--font-color);
}

.content-contact-wraper {
  padding: 25px 25px 0 25px;
}

.content-contact-wraper h3 {
  font-size: 18px;
  color: #000000;
  margin-bottom: 15px;
}

.content-contact-wraper .form-group {
  margin-bottom: 15px;
}

.content-contact-wraper .form-group .form-control {
  height: 45px;
  box-shadow: none;
  outline: none;
  border: 1px solid #eef2ff;
  font-size: var(--text-size);
}

.pacakage-listing-main-wraper {
  padding: 25px 25px 0 25px;
}

.pacakage-listing-main-wraper ul {
  list-style: none;
  padding-left: 0;
}

.pacakage-listing-main-wraper ul li {
}

.pacakage-listing-main-wraper ul li .listing-pacakge-wraper {
}

.pacakage-listing-main-wraper ul li .listing-pacakge-wraper a {
  display: block;
  text-decoration: none;
  background: #eff3ff;
  padding: 25px 15px 15px 15px;
  border: 1px solid #d8e0fb;
  border-radius: 10px;
  margin-bottom: 5px;
}

.pacakage-listing-main-wraper ul li:hover .listing-pacakge-wraper a,
.selected-package-wrapper a {
  background: var(--app-color-2) !important;
}

.ent-package a {
  background-color: var(--app-color-1) !important;
}

.pacakage-listing-main-wraper ul li:hover .listing-pacakge-wraper a h4,
.selected-package-wrapper a h4 {
  color: #fff !important;
}

.pacakage-listing-main-wraper
  ul
  li:hover
  .listing-pacakge-wraper
  a
  .price-listing,
.selected-package-wrapper a .price-listing {
  color: #fff;
}

.pacakage-listing-main-wraper
  ul
  li:hover
  .listing-pacakge-wraper
  a
  .pacakge-tip-info,
.selected-package-wrapper a .pacakge-tip-info {
  color: #ffffff;
}

.pacakage-listing-main-wraper
  ul
  li:hover
  .listing-pacakge-wraper
  a
  .package-trails-wrap,
.selected-package-wrapper a .package-trails-wrap,
.selected-package-wrapper a .pacakge-tip-info {
  color: #fff !important;
}

.pacakage-listing-main-wraper
  ul
  li:hover
  .listing-pacakge-wraper
  a
  .pacakge-info-list-wrap,
.selected-package-wrapper a .pacakge-info-list-wrap {
  background: var(--app-color-1);
}

.pacakge-info-list-wrap ul {
  padding-left: 0;
}

.pacakage-listing-main-wraper
  ul
  li:hover
  .listing-pacakge-wraper
  a
  .pacakge-info-list-wrap
  h5,
.selected-package-wrapper a .pacakge-info-list-wrap h5 {
  color: #fff;
}

.pacakage-listing-main-wraper
  ul
  li:hover
  .listing-pacakge-wraper
  a
  .pacakge-info-list-wrap
  ul
  li,
.selected-package-wrapper a .pacakge-info-list-wrap ul li {
  color: #fff;
}

.pacakage-listing-main-wraper
  ul
  li:hover
  .listing-pacakge-wraper
  a
  .btn-update-package,
.pacakage-listing-main-wraper
  ul
  li:hover
  .listing-pacakge-wraper
  a
  .ent-btn-main,
.selected-package-wrapper a .btn-update-package,
.selected-package-wrapper a .ent-btn-main {
  background: var(--app-color-1);
}

.pacakage-listing-main-wraper
  ul
  li:hover
  .listing-pacakge-wraper
  a
  .ent-btn-main {
  color: #fff !important;
}

.listing-pacakge-wraper a h4 {
  text-align: center;
  font-size: var(--heading-size);
  color: #000000;
}

.price-listing {
  position: relative;
}

.price-listing span {
  font-size: 18px;
  top: 20px;
  left: 100px;
  line-height: 0;
}

.price-listing {
  font-size: 50px;
  text-align: center;
  color: var(--app-color-1);
  line-height: 65px;
  letter-spacing: -5px;
}

.pacakge-tip-info {
  text-align: center;
  font-size: 13px;
  color: #333333;
}

.package-trails-wrap {
  text-align: center;
  color: var(--font-color);
  font-size: 16px;
}

.pacakge-info-list-wrap {
  background: #fff;
  padding: 20px 20px 10px 20px;
  border-radius: 10px;
  margin-top: 15px;
  min-height: 186.8px;
}

.pacakge-info-list-wrap h5 {
  font-size: var(--text-size);
  color: #000;
  margin-bottom: 10px;
}

.pacakge-info-list-wrap ul {
}

.pacakge-info-list-wrap ul li {
  margin-bottom: 5px;
  font-weight: var(--text-font-weight);
  font-size: var(--text-size);
  color: #333333;
}

.btn-update-package {
  display: flex;
  background: var(--app-color-2);
  padding: 12px 0;
  text-align: center;
  color: #fff;
  font-size: var(--text-size);
  font-weight: var(--heading-weigth) !important;
  border-radius: 6px;
  margin-top: 15px;
  font-weight: bold;
  max-height: 42px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.package-modal .modal-dialog {
  min-width: 1500px;
  max-height: fit-content;
}

.package-modal .modal-content {
  border-radius: 24px;
  max-width: 98vw;
}

.package-modal .modal-content .modal-header {
  padding-left: 40px !important;
  padding-right: 40px !important;
  font-weight: var(--heading-weigth);
}

.text-wrap-main {
  padding: 15px;
  font-size: var(--text-size);
}

.text-wrap-main-last {
  padding: 15px;
}

.add-more-module-btn {
  cursor: pointer;
  color: var(--app-color-1) !important;
  font-size: 15px !important;
}

.industry-modal-wrapper {
  height: auto !important;
  padding: 0 !important;
}

.industry-modal-wrapper .funkyradio {
  padding: 0px 0px 0 !important;
}

.module-select-heading {
  font-size: var(--heading-size) !important;
}

.module-btn-wrapper {
  display: flex;
  justify-content: end;
}

.module-modal-btn {
  width: 120px !important;
  background-color: var(--app-color-2) !important;
}

.module-modal-btn:hover {
  background-color: var(--app-color-1) !important;
}

.module-main-btn {
  min-width: 150px;
}

.remove-package-modal-btn {
  margin-top: 20px;
}

.modal-heading-wrap h3 {
  font-size: var(--heading-size);
  font-weight: var(--text-font-weight);
  color: var(--font-color);
  margin-bottom: 0 !important;
  margin-top: 18px;
}

.modal-heading-wrap span {
  font-weight: var(--heading-weigth);
}

.unsubscribe-modal .modal-content {
  min-height: 286px;
  min-width: 800px;
}

.toogle-main-wraper img {
  transition: transform 0.3s ease;
  margin-left: 5px;
}

.flipped {
  transform: scaleY(-1);
}

.main-delete-modal .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
}

.package-listing-signup {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-bottom: 50px;
}

.remove-card-btn {
  color: #ef2727;
  background-color: transparent;
  border: 1px solid #ef2727;
  border-radius: 4px;
  padding-left: 15px;
  padding-right: 15px;
}

.remove-card-btn:hover {
  color: #fff;
  background-color: #ef2727;
  border: 1px solid #ef2727;
}

.add-ons-btn {
  border: 1px solid var(--app-color-2);
  color: var(--app-color-2);
  background-color: transparent;
  margin-right: 10px;
  border-radius: 4px;
  padding-left: 15px;
  padding-right: 15px;
}

.add-ons-btn:hover {
  background-color: var(--app-color-2);
  color: #fff;
}

/* Delete confirmation */
.popup-delete-card-wraper {
  padding: 15px;
}

.popup-delete-card-wraper {
}

.popup-delete-card-wraper h2 {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.popup-delete-card-wraper h3 {
  font-size: var(--text-size);
  font-weight: 500;
  color: #333333;
}

.popup-delete-card-wraper p {
  padding: 5px 0;
  margin-bottom: 0px !important;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.popup-delete-card-wraper ul {
  margin-left: 15px;
  margin-bottom: 0px !important;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.credit-card-danger-wrap {
  border: 2px solid #f00;
  padding: 25px;
  padding-bottom: 0;
}

.credit-card-danger-wrap h4 {
  color: #f00;
  font-size: 20px;
}

.credit-card-danger-wrap p {
  color: #f00;
  font-size: var(--text-size);
  padding-top: 10px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #f00;
}

.form-check .form-check-input {
  box-shadow: none;
  border: 1px solid #f00;
}

.form-check label {
  color: #f00;
}

.credit-card-danger-wrap {
  margin-top: 15px;
}

.delete-confirm-modal .modal-header {
  position: relative;
}

.delete-confirm-modal .modal-header button {
  position: absolute;
  top: 0;
  right: 17px;
}

.delete-confirm-modal .modal-body {
  padding-left: 0;
  padding-right: 0;
}

.delete-confirm-modal .modal-content {
  min-width: 700px;
  border-radius: 6px !important;
}

.unsub-main-modal .modal-content {
  min-width: 300px !important;
}

/* .delete-sub-modal-main .modal-content {
  min-width: 700px !important;
} */

.sub-unsub {
  margin-right: 10px;
  cursor: pointer;
}

.form-package-delete-modal-check {
  margin-top: 10px;
}

.billing-sub-wrap {
  min-width: 1100px;
}

.table-module-wrap {
  max-width: 1260px;
}

.overview-sub-wrap {
  min-width: 100%;
}

.add-on-btn {
  background-color: var(--icon-color2) !important;
  color: white !important;
  border: 1px solid var(--icon-color2) !important;
  margin-left: 10px;
  min-width: 165.89px !important;
  height: 45px;
  border-radius: 6px;
  font-size: var(--text-size);
}

.add-on-btn:hover {
  background-color: var(--icon-color) !important;
  color: #fff !important;
  border: 1px solid var(--icon-color) !important;
}

.update-plan-btn {
  height: 45px;
  background-color: var(--icon-color2);
  color: #fff !important;
  border: 1px solid var(--icon-color2);
  border-radius: 6px;
  width: 165px;
  font-size: var(--text-size);
  margin-left: 10px;
}

.update-plan-btn:hover {
  background-color: var(--icon-color);
  border: 1px solid var(--icon-color);
}

.add-on-popup-wraper {
  /* padding: 25px; */
}

.add-on-popup-wraper h2 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0px !important;
}

.add-on-popup-wraper h2 span {
  font-weight: var(--text-font-weight);
}

.add-on-popup-wraper h2 strong {
  width: 100px;
  float: left;
  position: relative;
  top: 6px;
  font-weight: 500;
}

.add-on-popup-wraper .form-control {
  width: 170px;
  float: left;
  box-shadow: none;
  height: 35px;
  background: #e9eeff;
  border: none;
  outline: none;
  border-radius: 6px;
  font-size: var(--text-size);
  padding-left: 15px;
  appearance: auto;
}

.select-seat-wrap {
  background: #e9eeff;
  min-width: fit-content;
  height: 100%;
  padding-right: 5px;
  border-radius: 6px;
}

.add-onn-btn {
  margin-top: 24px;
}

.package-info-wraper-singal {
  border: 1px solid #eef2ff;
  margin-top: 25px;
  border-radius: 8px;
  overflow: hidden;
}

.basic-package-listing-wrap {
  padding: 25px;
}

.basic-package-listing-wrap h3 {
  color: #333;
  font-size: 20px;
  font-weight: 400;
}

.basic-package-listing-wrap ul {
  list-style: none;
  margin-top: 5px;
  padding-left: 0 !important;
}

.basic-package-listing-wrap ul li {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.basic-package-listing-wrap ul li::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cdefs%3E%3Cmask id='ipSCheckOne0'%3E%3Cg fill='none' stroke-linejoin='round' stroke-width='4'%3E%3Cpath fill='%23fff' stroke='%23fff' d='M24 44a19.937 19.937 0 0 0 14.142-5.858A19.937 19.937 0 0 0 44 24a19.938 19.938 0 0 0-5.858-14.142A19.937 19.937 0 0 0 24 4A19.938 19.938 0 0 0 9.858 9.858A19.938 19.938 0 0 0 4 24a19.937 19.937 0 0 0 5.858 14.142A19.938 19.938 0 0 0 24 44Z'/%3E%3Cpath stroke='%23000' stroke-linecap='round' d='m16 24l6 6l12-12'/%3E%3C/g%3E%3C/mask%3E%3C/defs%3E%3Cpath fill='%23000' d='M0 0h48v48H0z' mask='url(%23ipSCheckOne0)'/%3E%3C/svg%3E");
  background-color: var(--icon-color);
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  margin-right: 5px;
}

.basic-package-listing-wrap ul li::before img {
  width: 100%;
  height: 100%;
}

.plan-listing-amount-wraper {
  background: var(--icon-color);
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plan-listing-amount-wraper span {
  color: #fff;
  display: block;
  font-size: var(--text-size);
}

.add-on-modal .btn-close,
.add-sum-modal .btn-close {
  margin-bottom: 22px !important;
}

.price-amount {
  font-size: 60px;
  color: #fff;
  font-weight: 500;
  line-height: 44px;
  display: block;
}

.price-amount strong {
  font-size: 16px;
  font-weight: var(--text-font-weight);
}

.plan-info-wrap {
  margin-bottom: 10px;
}

.add-on-modal .modal-content {
  min-width: 700px;
}

.add-on-modal .modal-header {
  padding-bottom: 1px !important;
}
.add-sum-modal .modal-header {
  padding-bottom: 1px !important;
}

.package-info-data p {
  margin-bottom: 15px !important;
}

.package-info-data p span {
  color: var(--app-color-1) !important;
}

.package-info-data-main p {
  margin-bottom: 0px !important;
  font-size: 22px;
  font-weight: 500;
}

.update-modal-body hr {
  color: #c0dde3;
}

.update-modal-body hr:first-child {
  margin-top: 5px;
  margin-bottom: 1rem;
}

.plan-wrap p {
  width: 50%;
}

.plan-wrap p:last-child {
  text-align: end;
}

.summery-section .modal-content {
  min-width: 500px;
}

.go-back {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  cursor: pointer;
}

.update-package-summery-modal,
.user-select-modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.update-package-summery-modal .modal-dialog,
.user-select-modal .modal-dialog {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

/* New Wrapper*/
.package-listing-new-wraper {
  padding: 25px;
}

.package-listing-new-wraper h2 {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 5px;
}

.package-listing-new-wraper p {
  font-weight: var(--text-font-weight);
  font-size: var(--text-size);
  padding-bottom: 25px;
  margin-bottom: 0px !important;
}

.modal-package-list {
  padding: 10px 25px 25px 25px !important;
}

.package-listing-new-wraper .form-group {
  display: flex;
  vertical-align: middle;
}

.package-listing-new-wraper .form-group label {
  align-items: center;
  display: flex;
}

.seats-form-wrap {
  border: 1px solid #e5e9f7;
  margin: 0px 10px;
}

.package-listing-new-wraper .form-control {
  width: 150px;
  margin-right: 5px;
  border: none;
  box-shadow: none;
  border-radius: 0;
  appearance: auto;
}

.plan-seelct {
  margin-top: 5px;
}

.plan-seelct a {
  color: #333;
  font-size: var(--text-size);
  font-weight: 400;
  /* margin-right: 25px; */
  cursor: pointer;
  text-decoration: none;
}

.plan-seelct a:first-child {
  margin-left: 20px;
}

.plan-seelct a:last-child {
  margin-left: 10px;
}

.toogle-main-wraper {
  text-align: center;
}

.toogle-main-wraper a {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.table-pacakge-wrap .table {
  border: 1px solid #f1f4ff;
}

.table-pacakge-wrap .table tr {
  border: 1px solid #f1f4ff;
}

.table-pacakge-wrap .table tr td {
  border: 1px solid #f1f4ff;
  color: #333;
  font-size: var(--text-size);
  padding: 10px 15px;
  font-weight: var(--text-font-weight);
}

.table-pacakge-wrap {
  margin-top: 25px;
}

.heading-pacakge-table td {
  font-size: 18px;
  font-weight: 500 !important;
  color: #333 !important;
}

.table-wrap-package > :not(caption) > * > * {
  padding: 0 !important;
}

.package-listing-modal-wrap {
  padding: 25px 0px 0px 0px !important;
}

.package-wrap-modal {
  position: relative;
}

.modal-radio {
  position: absolute;
  top: 20px;
  left: 35px;
  transform: scale(1.5);
}

.selected-plan {
  color: var(--app-color-1) !important;
}

.update-package-summery-modal .modal-content {
  max-width: 500px !important;
  padding: 10px;
}

.update-package-summery-modal .modal-content .modal-header {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.package-modal .modal-dialog {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.ent-name img {
  width: 50px;
  height: 50px;
}

.ent-name h5 {
  padding-bottom: 0 !important;
  text-align: center !important;
  letter-spacing: 0 !important;
  line-height: 1 !important;
  color: #fff;
  font-size: 50px;
  font-size: 25px;
  margin-top: 10px;
}

.go-back img {
  border: 1px solid #c0dde3;
  border-radius: 4px;
  margin-right: 5px;
}

.ent-name p {
  padding-bottom: 0 !important;
  text-align: center !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
  color: #fff;
  font-size: var(--text-size);
  margin-bottom: 0.7rem !important;
}

.ent-name-2 p {
  margin-top: 9px;
}

.ent-btn-main {
  background-color: #fff;
  color: #333;
}

.ent-btn-main:hover {
  color: #fff;
}

.selected-package-main a {
  background-color: var(--app-color-2) !important;
  color: #fff !important;
}

.selected-package-main a h4 {
  color: #fff !important;
}

.selected-package-main .price-listing {
  color: #fff !important;
}

.selected-package-main .pacakge-tip-info {
  color: #fff !important;
}
.selected-package-main .package-trails-wrap {
  color: #fff !important;
}
.selected-package-main .pacakge-info-list-wrap {
  background-color: var(--app-color-1) !important;
}
.selected-package-main .pacakge-info-list-wrap h5 {
  color: #fff !important;
}
.selected-package-main .pacakge-info-list-wrap ul li {
  color: #fff !important;
}

.selected-package-main .btn-update-package {
  background-color: var(--app-color-1) !important;
}
.selected-package-main .ent-btn-main {
  color: #fff !important;
}

.ent-li {
  margin-top: 25px !important;
}

.table-history {
  overflow-x: visible !important;
}

.table-history .table {
  min-width: 900px;
}

.sub-radio {
  position: absolute;
  left: 35px;
  top: 12px;
}

.unsub-pc {
  font-weight: var(--text-font-weight) !important;
  margin-bottom: 20px;
}

.customradio-role {
  align-items: center;
  border: 2px solid var(--app-color-1);
  border-radius: 50%;
  display: flex;
  height: 18px;
  justify-content: center;
  margin-right: 4px;
  width: 18px;
}

.customradio-role input {
  display: none;
}

.customradio-role input:checked + .radio-mark {
  display: inline-block;
  opacity: 1;
}

.customradio-role .radio-mark {
  background: var(--app-color-1);
  border-radius: 50%;
  display: none;
  height: calc(100% - 6px);
  opacity: 0;
  transition: opacity 0.3s;
  width: calc(100% - 6px);
}

.next-button-wraper button {
  align-items: center;
  background: var(--app-color-2);
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px 20px -6px #8b9bce;
  color: #fff;
  display: flex;
  font-size: var(--text-size);
  font-weight: 500;
  height: 50px;
  justify-content: center;
  outline: none;
  padding: 0 20px;
  width: 100%;
}

.listing-pacakge-wraper a {
  height: 100%;
}

@media (max-width: 1399px) {
  .package-container {
    min-width: 98% !important;
  }
}

@media (max-width: 1372px) {
  .billing-sub-wrap {
    min-width: 100% !important;
  }
}

@media (max-width: 1330px) {
  .table-billing-main {
    min-width: 1000px !important;
  }
}

@media (max-width: 991px) {
  .packages-wrapper h3 {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .module-modal-btn {
    width: 100% !important;
  }

  .delete-confirm-modal .modal-content {
    min-width: 99vw;
  }
  .packages-wrapper h3 {
    padding: 0px 18px;
  }
}

@media (max-width: 500px) {
  .package-listing-signup .form-group {
    flex-direction: column;
  }
  .package-listing-signup {
    margin-bottom: 120px;
  }
}

/* CSS role */
/* CSS media */
