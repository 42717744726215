/* SASS Variables */
$signin-btn-text-color: #111a37;
$header-text-color: #1f2a49;
$para-text-color: #111a37;
$anchor-text: #394b87;

:root {
  --transition-duration: 0.5s;
  /* Adjust the duration as needed */
  --transition-timing-function: ease;
  /* Adjust the timing function as needed */
  --heading-size: 20px;
  --heading-weigth: 500;
  --heading-weight: 500;
  --text-font-family: "Poppins", sans-serif;
  --text-size: 14px;
  --font-color-2: #394b87;
  --button-bg: var(--app-color-1);
  --button-bg-hover: var(--app-color-2);
  --button-text-color: #fff;
  --text-font-weight: 300;
  --scroll-thumb: #c7c7c7;
  overflow: hidden;
}

[data-theme="default"] {
  --app-color-1: #94abff;
  --app-color-2: #394b87;
  --font-color: #333333;
  --exotic-font-color: #394b87;
  --background-dashboard-color: #e9eeff;
  --card-color: #fff;
  --border-color: #e6eaf7;
  --border-color-two: #ddd;
  --img-color: var(--app-color-1);
  --selection-bg: #f4f6ff;
  --search-bar-bg: #fff;
  --input-border-color: #e9eeff;
  --exotic-border-color: var(--app-color-2);
  --btn-transparent: transparent;
  --divider-color: #f4f6ff;
  --divider-color2: #000;
  --divider-color3: rgba(0, 0, 0, 0.1);
  --list-menu-dropdown-border-color: #dee2e6;
  --icon-color: var(--app-color-1);
  --icon-color2: var(--app-color-2);
  --selected-route-color: #f4f6ff;
  --selected-route-color-svg: var(--app-color-2);
  --exotic-card-color: var(--app-color-1);
  --exotic-btn-color: var(--app-color-2);
  --filter-section-bg-color: #f4f6ff;
  --filter-section-border: none;
  --table-row: #fff;
  --download-btn-color: #e9eeff;
  --progress-timeline-bg: #e9eeff;
  --select-dropdown-option-color: #fff;
  --select-dropdown-option-font-color: #333;
  --shimmer-bg: #f6f7f8;
  --shimmer-highlight: #e9ebee;
  --text-message-bg-1: #f2f5ff;
  --chat-input-bg: #f2f5ff;
  --no-data-section-bg: #f4f6ff;
  --no-data-section-border: #dce2ff;
  --card-color-light: #fff;
}

[data-theme="color1"] {
  --app-color-1: #75be6f;
  --app-color-2: #44b237;
  --font-color: #333333;
  --exotic-font-color: #44b237;
  --background-dashboard-color: #e9eeff;
  --card-color: #fff;
  --border-color: #e6eaf7;
  --border-color-two: #ddd;
  // --img-color: var(--app-color-1);
  --img-color: invert(70%) sepia(26%) saturate(661%) hue-rotate(68deg)
    brightness(92%) contrast(89%);
  --selection-bg: #f4f6ff;
  --search-bar-bg: #fff;
  --input-border-color: #e9eeff;
  --exotic-border-color: var(--app-color-2);
  --btn-transparent: transparent;
  --divider-color: #f4f6ff;
  --divider-color2: #000;
  --divider-color3: rgba(0, 0, 0, 0.1);
  --list-menu-dropdown-border-color: #dee2e6;
  --icon-color: var(--app-color-1);
  --selected-route-color: #f4f6ff;
  --selected-route-color-svg: var(--app-color-2);
  --exotic-card-color: var(--app-color-1);
  --exotic-btn-color: var(--app-color-2);
  --table-row: #fff;
  --filter-section-bg-color: #f4f6ff;
  --filter-section-border: none;
  --download-btn-color: #e9eeff;
  --progress-timeline-bg: #e9eeff;
  --select-dropdown-option-color: #fff;
  --select-dropdown-option-font-color: #333;
  --shimmer-bg: #f6f7f8;
  --shimmer-highlight: #e9ebee;
  --icon-color2: var(--app-color-2);
  --text-message-bg-1: #f2f5ff;
  --chat-input-bg: #f2f5ff;
  --no-data-section-bg: #f4f6ff;
  --no-data-section-border: #dce2ff;
  --card-color-light: #fff;
}

[data-theme="color2"] {
  --app-color-1: #ede151;
  --app-color-2: #dece05;
  --font-color: #333333;
  --exotic-font-color: #dece05;
  --background-dashboard-color: #e9eeff;
  --card-color: #fff;
  --border-color: #e6eaf7;
  --border-color-two: #ddd;
  // --img-color: var(--app-color-1);
  --img-color: invert(83%) sepia(72%) saturate(426%) hue-rotate(356deg)
    brightness(102%) contrast(86%);
  --selection-bg: #f4f6ff;
  --search-bar-bg: #fff;
  --input-border-color: #e9eeff;
  --exotic-border-color: var(--app-color-2);
  --btn-transparent: transparent;
  --divider-color: #f4f6ff;
  --divider-color2: #000;
  --divider-color3: rgba(0, 0, 0, 0.1);
  --list-menu-dropdown-border-color: #dee2e6;
  --icon-color: var(--app-color-1);
  --icon-color2: var(--app-color-2);
  --selected-route-color: #f4f6ff;
  --selected-route-color-svg: var(--app-color-2);
  --exotic-card-color: var(--app-color-1);
  --exotic-btn-color: var(--app-color-2);
  --table-row: #fff;
  --filter-section-bg-color: #f4f6ff;
  --filter-section-border: none;
  --download-btn-color: #e9eeff;
  --progress-timeline-bg: #e9eeff;
  --select-dropdown-option-color: #fff;
  --select-dropdown-option-font-color: #333;
  --shimmer-bg: #f6f7f8;
  --shimmer-highlight: #e9ebee;
  --text-message-bg-1: #f2f5ff;
  --chat-input-bg: #f2f5ff;
  --no-data-section-bg: #f4f6ff;
  --no-data-section-border: #dce2ff;
  --card-color-light: #fff;
}

[data-theme="color3"] {
  --app-color-1: #eead45;
  --app-color-2: #d58500;
  --font-color: #333333;
  --exotic-font-color: #d58500;
  --background-dashboard-color: #e9eeff;
  --card-color: #fff;
  --border-color: #e6eaf7;
  --border-color-two: #ddd;
  // --img-color: var(--app-color-1);
  --img-color: invert(83%) sepia(72%) saturate(426%) hue-rotate(356deg)
    brightness(102%) contrast(86%);
  --selection-bg: #f4f6ff;
  --search-bar-bg: #fff;
  --input-border-color: #e9eeff;
  --exotic-border-color: var(--app-color-2);
  --btn-transparent: transparent;
  --divider-color: #f4f6ff;
  --divider-color2: #000;
  --divider-color3: rgba(0, 0, 0, 0.1);
  --list-menu-dropdown-border-color: #dee2e6;
  --icon-color: var(--app-color-1);
  --selected-route-color: #f4f6ff;
  --selected-route-color-svg: var(--app-color-2);
  --exotic-card-color: var(--app-color-1);
  --exotic-btn-color: var(--app-color-2);
  --table-row: #fff;
  --filter-section-bg-color: #f4f6ff;
  --filter-section-border: none;
  --download-btn-color: #e9eeff;
  --progress-timeline-bg: #e9eeff;
  --select-dropdown-option-color: #fff;
  --select-dropdown-option-font-color: #333;
  --shimmer-bg: #f6f7f8;
  --shimmer-highlight: #e9ebee;
  --icon-color2: var(--app-color-2);
  --text-message-bg-1: #f2f5ff;
  --chat-input-bg: #f2f5ff;
  --no-data-section-bg: #f4f6ff;
  --no-data-section-border: #dce2ff;
  --card-color-light: #fff;
}

[data-theme="color4"] {
  --app-color-1: #5b89bd;
  --app-color-2: #014596;
  --font-color: #333333;
  --exotic-font-color: #014596;
  --background-dashboard-color: #e9eeff;
  --card-color: #fff;
  --border-color: #e6eaf7;
  --border-color-two: #ddd;
  // --img-color: var(--app-color-1);
  --img-color: invert(54%) sepia(29%) saturate(723%) hue-rotate(171deg)
    brightness(90%) contrast(88%);
  --selection-bg: #f4f6ff;
  --search-bar-bg: #fff;
  --input-border-color: #e9eeff;
  --exotic-border-color: var(--app-color-2);
  --btn-transparent: transparent;
  --divider-color: #f4f6ff;
  --divider-color2: #000;
  --divider-color3: rgba(0, 0, 0, 0.1);
  --list-menu-dropdown-border-color: #dee2e6;
  --icon-color: var(--app-color-1);
  --selected-route-color: #f4f6ff;
  --selected-route-color-svg: var(--app-color-2);
  --exotic-card-color: var(--app-color-1);
  --exotic-btn-color: var(--app-color-2);
  --table-row: #fff;
  --filter-section-bg-color: #f4f6ff;
  --filter-section-border: none;
  --download-btn-color: #e9eeff;
  --progress-timeline-bg: #e9eeff;
  --select-dropdown-option-color: #fff;
  --select-dropdown-option-font-color: #333;
  --shimmer-bg: #f6f7f8;
  --shimmer-highlight: #e9ebee;
  --icon-color2: var(--app-color-2);
  --text-message-bg-1: #f2f5ff;
  --chat-input-bg: #f2f5ff;
  --no-data-section-bg: #f4f6ff;
  --no-data-section-border: #dce2ff;
  --card-color-light: #fff;
}

[data-theme="color5"] {
  --app-color-1: #8a59b5;
  --app-color-2: #500296;
  --font-color: #333333;
  --exotic-font-color: #500296;
  --background-dashboard-color: #e9eeff;
  --card-color: #fff;
  --border-color: #e6eaf7;
  --border-color-two: #ddd;
  // --img-color: var(--app-color-1);
  --img-color: invert(22%) sepia(93%) saturate(2081%) hue-rotate(260deg)
    brightness(65%) contrast(133%);
  --selection-bg: #f4f6ff;
  --search-bar-bg: #fff;
  --input-border-color: #e9eeff;
  --exotic-border-color: var(--app-color-2);
  --btn-transparent: transparent;
  --divider-color: #f4f6ff;
  --divider-color2: #000;
  --divider-color3: rgba(0, 0, 0, 0.1);
  --list-menu-dropdown-border-color: #dee2e6;
  --icon-color: var(--app-color-1);
  --selected-route-color: #f4f6ff;
  --selected-route-color-svg: var(--app-color-2);
  --exotic-card-color: var(--app-color-1);
  --exotic-btn-color: var(--app-color-2);
  --table-row: #fff;
  --filter-section-bg-color: #f4f6ff;
  --filter-section-border: none;
  --download-btn-color: #e9eeff;
  --progress-timeline-bg: #e9eeff;
  --select-dropdown-option-color: #fff;
  --select-dropdown-option-font-color: #333;
  --shimmer-bg: #f6f7f8;
  --shimmer-highlight: #e9ebee;
  --icon-color2: var(--app-color-2);
  --text-message-bg-1: #f2f5ff;
  --chat-input-bg: #f2f5ff;
  --no-data-section-bg: #f4f6ff;
  --no-data-section-border: #dce2ff;
  --card-color-light: #fff;
}

[data-theme="color6"] {
  --app-color-1: #d6293e;
  --app-color-2: #a30013;
  --font-color: #333333;
  --exotic-font-color: #a30013;
  --background-dashboard-color: #e9eeff;
  --card-color: #fff;
  --border-color: #e6eaf7;
  --border-color-two: #ddd;
  // --img-color: var(--app-color-1);
  --img-color: invert(18%) sepia(99%) saturate(5907%) hue-rotate(353deg)
    brightness(93%) contrast(123%);
  --selection-bg: #f4f6ff;
  --search-bar-bg: #fff;
  --input-border-color: #e9eeff;
  --exotic-border-color: var(--app-color-2);
  --btn-transparent: transparent;
  --divider-color: #f4f6ff;
  --divider-color2: #000;
  --divider-color3: rgba(0, 0, 0, 0.1);
  --list-menu-dropdown-border-color: #dee2e6;
  --icon-color: var(--app-color-1);
  --selected-route-color: #f4f6ff;
  --selected-route-color-svg: var(--app-color-2);
  --exotic-card-color: var(--app-color-1);
  --exotic-btn-color: var(--app-color-2);
  --table-row: #fff;
  --filter-section-bg-color: #f4f6ff;
  --filter-section-border: none;
  --download-btn-color: #e9eeff;
  --progress-timeline-bg: #e9eeff;
  --select-dropdown-option-color: #fff;
  --select-dropdown-option-font-color: #333;
  --shimmer-bg: #f6f7f8;
  --shimmer-highlight: #e9ebee;
  --icon-color2: var(--app-color-2);
  --text-message-bg-1: #f2f5ff;
  --chat-input-bg: #f2f5ff;
  --no-data-section-bg: #f4f6ff;
  --no-data-section-border: #dce2ff;
  --card-color-light: #fff;
}

[data-theme="color7"] {
  --app-color-1: #282828;
  --app-color-2: #000;
  --font-color: #fff;
  --exotic-font-color: #fff;
  --border-color: rgba(241, 244, 255, 0.2);
  --border-color-two: #4c4c4c;
  --background-dashboard-color: #000;
  --card-color: #282828;
  // --img-color: var(--app-color-1);
  --img-color: invert(18%) sepia(99%) saturate(5907%) hue-rotate(353deg)
    brightness(93%) contrast(123%);
  --selection-bg: #000;
  --search-bar-bg: #000;
  --input-border-color: #000;
  --exotic-border-color: var(--app-color-2);
  --btn-transparent: #000;
  --divider-color: #4c4c4c;
  --divider-color2: #4c4c4c;
  --divider-color3: #4c4c4c;
  --list-menu-dropdown-border-color: #4c4c4c;
  --icon-color: #94abff;
  --selected-route-color: var(--app-color-2);
  --selected-route-color-svg: #94abff;
  --exotic-card-color: #94abff;
  --exotic-btn-color: #394b87;
  --filter-section-bg-color: #282828;
  --filter-section-border: 2px solid #394b87;
  --download-btn-color: #94abff;
  --table-row: var(--app-color-1);
  --progress-timeline-bg: #757575;
  --select-dropdown-option-color: var(--app-color-2);
  --select-dropdown-option-font-color: #fff;
  --shimmer-bg: #333;
  --shimmer-highlight: #444;
  --icon-color2: #394b87;
  --text-message-bg-1: #33394e;
  --chat-input-bg: #000000;
  --no-data-section-bg: #4e5673;
  --no-data-section-border: #94abff;
  --card-color-light: #333;
}

/* CSS Document */
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  // overflow-y: hidden;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif !important;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background-color: #666;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0;
  padding: 0;
}

p {
  color: var(--font-color);
}

a {
  text-decoration: none;
}

.clear {
  clear: both;
}

img {
  max-width: 100%;
}

.carousel-indicators {
  align-items: end;
}

.carousel-indicators button:nth-child(2) {
  /* Your styles for the 2nd button here */
  /* For example, you can add margins to the 2nd button */
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.table-two > :not(caption) > * > * {
  padding: 0 !important;
  color: none;
}

.Toastify__toast-body > div:last-child {
  font-size: 12px;
  font-family: "Manrope", sans-serif !important;
}

// Dropdown reac-bootstrap
.no-arrow-dd::after {
  display: none !important;
}

.no-arrow-dd {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
}

.no-arrow-dd img {
  min-width: 22px;
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  font-weight: var(--text-font-weight);
  background-color: var(--card-color);
  color: var(--font-color);
}
.accordion-body {
  font-size: 14px;
  font-weight: var(--text-font-weight);
  background-color: var(--card-color);
  color: var(--font-color);
}
.accordion-item {
  border: 1px solid var(--divider-color);
}
.package-page-row li {
  width: 19.667%;
}

.package-page-row li .listing-pacakge-wraper {
  display: flex;
  height: 100%;
}

.modal-content {
  color: var(--font-color);
  border-radius: 6px !important;
  background-color: var(--card-color) !important;
}

.modal-header {
  position: relative;
}

.add-on-modal .modal-header button {
  position: absolute;
  top: 0;
  right: 2px;
}

.package-modal .modal-header button {
  position: absolute;
  top: 14px;
  right: 16px;
}

.update-package-summery-modal .modal-header button {
  position: absolute;
  top: 4px;
  right: 4px;
}

.user-select-modal .modal-header {
  margin-top: 1rem;
}

.user-select-modal .modal-header button {
  top: 0px !important;
}

.invite-modal-span {
  font-weight: var(--text-font-weight) !important;
}

.global-spinner {
  color: var(--app-color-1);
}

.see-more-txt {
  color: var(--icon-color);
  cursor: pointer;
}

// .ag-header-cell,
// .ag-header-group-cell {
//   max-height: 35px !important;
// }

.task-progress-doghnut-chart {
  width: 20px !important;
  height: 20px !important;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  z-index: 0 !important;
  border-bottom: 1px solid #f1f4ff !important;
}

.tox .tox-edit-area::before {
  // border: 2px solid rgba(13, 110, 253, 0.25) !important ;
}

// For multiselect component where there is border when entering input field
.css-1yvwecq-ValueContainer div:first-child,
.css-jc0r9t div:first-child,
.css-gz0hkv-ValueContainer div:last-child,
.css-1iq9at8 div:last-child {
  border: none !important;
}

// .tox.tox-edit-focus .tox-edit-area{
//   outline: 0 !important;
//   box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
//   border: none !important;
// }

.shimmer-avatar {
  margin-bottom: 0px !important;
  display: flex !important;
}
.invite-modal-spinner {
  color: var(--icon-color);
}
.shimmer-card {
  background-color: var(--card-color);
}

.record-count-under-search {
  position: absolute;
  right: 0;
  color: #000 !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.css-1dimb5e-singleValue {
  color: var(--font-color) !important;
}
.pagination .disabled > .page-link {
  background: var(--border-color);
  border-color: var(--border-color) !important;
  color: var(--font-color) !important;
}
.pagination .active > .page-link {
  background-color: var(--icon-color) !important;
  color: #fff !important;
  border: var(--icon-color) !important;
}
.pagination .page-link {
  color: var(--font-color) !important;
  background-color: var(--search-bar-bg);
  border-color: var(--divider-color) !important;
}
.shimmer,
.shimmer-table-row:not(.shimmer-table-row--header) {
  background: linear-gradient(
    90deg,
    var(--shimmer-bg) 25%,
    var(--shimmer-highlight) 50%,
    var(--shimmer-bg) 75%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.btn-close {
  // --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23var(--font-color)'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  filter: 1;
}

.delete-btn-1 {
  background-color: var(--icon-color2);
  border: none;
  height: 50px;
  color: #fff;
}

.delete-btn-2 {
  background-color: var(--icon-color);
  border: none;
  height: 50px;
  color: #fff;
}

.delete-btn-2:hover {
  background-color: var(--icon-color);
}

.delete-btn-1:hover {
  background-color: var(--icon-color);
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
@media screen and (max-width: 1100px) {
  .package-page-row li {
    width: 32%;
  }
}

@media screen and (max-width: 900px) {
  .package-page-row li {
    width: 50%;
  }
}

@media screen and (max-width: 800px) {
  .package-page-row li {
    width: 100%;
  }
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 99999 !important;
}
