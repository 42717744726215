.app_wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  position: relative;
  width: 100vw;
  background-image: url("../../../assets/bg.png");
}

.container {
  max-width: 1200px;
}

/* logo */
.logo_wrapper {
  margin-bottom: 1rem;
}

.logo_image {
  height: 40px;
}

/* membership details */
.membership_details_wrapper {
  padding: 0.5rem;
}

.membership_type_title {
  color: #606060;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1.25rem;
}

.membership_options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.membership_option {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  transition: all 0.2s ease;
}

.membership_option.selected {
  border-color: #3b5998;
  background-color: rgba(59, 89, 152, 0.05);
}

.membership_radio {
  margin: 0;
}

.membership_radio label {
  width: 100%;
}

.membership_option_content {
  display: flex;
  flex-direction: row;
  margin-left: 0.5rem;
}

.membership_option_title {
  font-weight: 500;
  color: #333;
}

.membership_option_price {
  font-size: 0.85rem;
  color: #666;
  margin-top: 0.25rem;
}

.save_badge {
  background-color: none !important;
  width: max-content !important;
  color: #94abff;
  text-align: right;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0.25rem;
}

.users_selection {
  margin-bottom: 2rem;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fdfdfd;
}

.user_selection_wrap {
  padding: 15px 16px;
}

.users_selection_title {
  color: #394b84;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1rem;
}

.users_selection_row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.users_dropdown {
  width: 180px !important;
  height: 45px !important;
  border-radius: 6px;
  border: 1px solid #ddd;
  padding: 0.5rem;
  font-size: 0.95rem;
}

.price_calculation {
  width: max-content;
  font-size: 0.9rem;
  color: #666;
  text-align: right;
}

.highlight_price {
  color: #94abff;
  font-weight: 600;
}

.total_amount_section {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 15px 16px;
}

.total_amount_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total_amount_label {
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
}

.total_amount_value {
  font-size: 1.5rem;
  font-weight: 600;
  color: #3b5998;
}

.billing_period {
  font-size: 0.85rem;
  color: #666;
  text-align: right;
}

.terms_section {
  text-align: center;
}

.terms_text {
  font-size: 0.85rem;
  color: #666;
}

.terms_link {
  color: #94abff;
  text-decoration: none;
}

.terms_link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .users_selection_row {
    flex-direction: column;
  }

  .price_calculation {
    text-align: left;
    margin-top: 0.5rem;
  }
}

/* payment form  */

.payment_form {
  margin-bottom: 2rem;
}

.payment_methods_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.billed_to_text {
  color: #394b84;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 1rem;
  margin-bottom: 0;
}

.payment_methods {
  display: flex;
  align-items: center;
}

.payment_method_icon {
  margin-right: 0.5rem;
  height: 24px;
}

.payment_form_label {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.5rem;
}

.payment_form_input {
  height: 45px;
  border-radius: 6px;
  border: 1px solid #ddd;
  padding: 0.75rem;
  font-size: 0.95rem;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.payment_form_input:focus {
  border-color: #3b5998;
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.1);
}

.payment_form_row {
  display: flex;
  gap: 1rem;
}

.payment_form_expiry {
  flex: 1;
}

.payment_form_cvv {
  flex: 1;
}

/* Stripe Elements Styling */
.stripe_element_container {
  height: 45px;
  border-radius: 6px;
  border: 1px solid #ddd;
  padding: 0 0.75rem;
  font-size: 0.95rem;
  background-color: white;
  display: flex;
  align-items: center;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.stripe_element_container:focus-within {
  border-color: #3b5998;
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.1);
}

.stripe_element_container.is-invalid {
  border-color: #dc3545;
}

.stripe_element {
  flex: 1;
  padding: 0.75rem 0;
}

.invalid_feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.payment_submit_btn {
  width: 100%;
  height: 48px;
  background-color: #394b87 !important;
  border: none !important;
  border-radius: 6px;
  font-weight: 500;
  margin-top: 1rem;
}

.payment_submit_btn:hover,
.payment_submit_btn:focus {
  background-color: #607fe7ae !important;
}

.payment_submit_btn:disabled {
  background-color: #6c757d;
}

@media (max-width: 576px) {
  .payment_form_row {
    flex-direction: column;
    gap: 0;
  }
}

/* payment page */

.payment_card {
  border: none !important;
  border-radius: 10px !important;
  background: #fff !important;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12) !important;
}

.payment_left_col {
  padding-right: 2rem;
}

.payment_right_col {
  padding-left: 2rem;
  border-left: 1px solid #eee;
}

.payment_title {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  color: #000;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.payment_subtitle {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2rem;
}

@media (max-width: 991px) {
  .payment_left_col,
  .payment_right_col {
    padding: 1rem;
  }

  .payment_right_col {
    border-left: none;
    border-top: 1px solid #eee;
    margin-top: 2rem;
    padding-top: 2rem;
  }
}
